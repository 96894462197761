var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-2",attrs:{"no-body":""}},[_c('h3',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t('DataAccess.ConnectionsStrings.Edit.modifyConnectionString'))+" ")]),_c('validation-observer',{ref:"refFormObserver",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)},"reset":function($event){$event.preventDefault();return _vm.resetForm($event)}}},[_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.ConnectionsStrings.columns.name'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.connectionstring.nombre),callback:function ($$v) {_vm.$set(_vm.connectionstring, "nombre", $$v)},expression:"connectionstring.nombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"user","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.ConnectionsStrings.columns.user'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.connectionstring.usuario),callback:function ($$v) {_vm.$set(_vm.connectionstring, "usuario", $$v)},expression:"connectionstring.usuario"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"md":"4"}},[_c('validation-provider',{attrs:{"name":"New password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('AppUser.edit.TabChangePassword.newPassword'),"label-for":"new-password"}},[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"new-password","name":"new-password","placeholder":_vm.$t('AppUser.edit.TabChangePassword.newPassword'),"state":errors.length > 0 ? false:null,"type":_vm.passwordFieldTypeNew,"trim":""},model:{value:(_vm.connectionstring.contrasenia),callback:function ($$v) {_vm.$set(_vm.connectionstring, "contrasenia", $$v)},expression:"connectionstring.contrasenia"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIconNew},on:{"click":_vm.togglePasswordNew}})],1)],1),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"ServidorInstancia","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.ConnectionsStrings.columns.instanceServer'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.connectionstring.servidorInstancia),callback:function ($$v) {_vm.$set(_vm.connectionstring, "servidorInstancia", $$v)},expression:"connectionstring.servidorInstancia"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"DatabaseName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.ConnectionsStrings.columns.nameDatabase'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.connectionstring.baseDatosNombre),callback:function ($$v) {_vm.$set(_vm.connectionstring, "baseDatosNombre", $$v)},expression:"connectionstring.baseDatosNombre"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.ConnectionsStrings.columns.description'),"state":errors.length > 0 ? false:null}},[_c('b-form-input',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"trim":""},model:{value:(_vm.connectionstring.descripcion),callback:function ($$v) {_vm.$set(_vm.connectionstring, "descripcion", $$v)},expression:"connectionstring.descripcion"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('validation-provider',{attrs:{"name":"system"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('DataAccess.ConnectionsStrings.columns.system')}},[_c('v-select',{attrs:{"label":"texto","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"clearable":true,"options":_vm.systems,"reduce":function (option) { return option.id; }},model:{value:(_vm.connectionstring.sistemaId),callback:function ($$v) {_vm.$set(_vm.connectionstring, "sistemaId", $$v)},expression:"connectionstring.sistemaId"}}),_c('b-form-invalid-feedback',[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}],null,true)})],1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-2",attrs:{"disabled":_vm.testConnection,"type":"button","variant":"outline-secondary"},on:{"click":function($event){return _vm.testingConnectionStringIsValid(_vm.connectionstring)}}},[(_vm.testConnection)?_c('b-spinner',{staticClass:"mr-1",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.$t('Lists.TestConnectionString'))+" ")],1),_c('b-button',{staticClass:"mb-1 mb-sm-0 mr-0 mr-sm-1",attrs:{"variant":"primary","disabled":_vm.isConnectionStringValid !== true,"block":_vm.$store.getters['app/currentBreakPoint'] === 'xs',"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Lists.saveChanges'))+" ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }