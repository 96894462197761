<template>
  <b-card
    no-body
    class="p-2"
  >
    <h3 class="mb-2">
      {{ $t('DataAccess.ConnectionsStrings.Edit.modifyConnectionString') }}
    </h3>
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <!-- Name field -->
          <b-col
            cols="12"
            md="4"
          >
            <!-- Name Input -->
            <validation-provider
              #default="{ errors }"
              name="name"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.ConnectionsStrings.columns.name')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="connectionstring.nombre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- user Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="user"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.ConnectionsStrings.columns.user')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="connectionstring.usuario"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Password Field -->
          <b-col md="4">
            <validation-provider
              #default="{ errors }"
              name="New password"
              rules="required"
            >
              <b-form-group
                :label="$t('AppUser.edit.TabChangePassword.newPassword')"
                label-for="new-password"
              >
                <b-input-group>
                  <b-form-input
                    id="new-password"
                    v-model="connectionstring.contrasenia"
                    name="new-password"
                    :placeholder="$t('AppUser.edit.TabChangePassword.newPassword')"
                    :state="errors.length > 0 ? false:null"
                    :type="passwordFieldTypeNew"
                    trim
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIconNew"
                      class="cursor-pointer"
                      @click="togglePasswordNew"
                    />
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Servidor Instancia Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="ServidorInstancia"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.ConnectionsStrings.columns.instanceServer')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="connectionstring.servidorInstancia"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- database name Field -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="DatabaseName"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.ConnectionsStrings.columns.nameDatabase')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="connectionstring.baseDatosNombre"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- DescriptionField -->
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="Description"
              rules="required"
            >
              <b-form-group
                :label="$t('DataAccess.ConnectionsStrings.columns.description')"
                :state="errors.length > 0 ? false:null"
              >
                <b-form-input
                  v-model="connectionstring.descripcion"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  trim
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col
            cols="12"
            md="4"
          >
            <validation-provider
              #default="{ errors }"
              name="system"
            >
              <b-form-group
                :label="$t('DataAccess.ConnectionsStrings.columns.system')"
              >
                <v-select
                  v-model="connectionstring.sistemaId"
                  label="texto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :clearable="true"
                  :options="systems"
                  :reduce="option => option.id"
                />
                <b-form-invalid-feedback>
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
        <b-button
          v-ripple.400="'rgba(186, 191, 199, 0.15)'"
          :disabled="testConnection"
          type="button"
          class="mr-2"
          variant="outline-secondary"
          @click="testingConnectionStringIsValid(connectionstring)"
        >
          <b-spinner
            v-if="testConnection"
            small
            class="mr-1"
          />
          {{ $t('Lists.TestConnectionString') }}
        </b-button>
        <b-button
          variant="primary"
          :disabled="isConnectionStringValid !== true"
          class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          :block="$store.getters['app/currentBreakPoint'] === 'xs'"
          type="submit"
        >
          {{ $t('Lists.saveChanges') }}
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BSpinner,
  BFormInvalidFeedback,
  BButton,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import generalDataService from '@/services/generalData.service'
import optionService from '@/services/option.service'
import formValidation from '@core/comp-functions/forms/form-validation'
import router from '@/router'
import { required } from '@validations'
import { ref } from '@vue/composition-api'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormInput,
    BFormInvalidFeedback,
    BFormGroup,
    BButton,
    BInputGroupAppend,
    BInputGroup,
    BSpinner,
    vSelect,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    /* SERVICES */
    const {
      fetchConnectionString,
      updateConnectionString,
      testConnectionString,
    } = generalDataService()

    const { fetchSystems } = optionService()

    // Data
    const connectionstring = ref({})
    fetchConnectionString(router.currentRoute.params, data => {
      connectionstring.value = data
    })
    const isConnectionStringValid = ref(false)
    const testConnection = ref(false)

    const testingConnectionStringIsValid = cadenaConexion => {
      testConnection.value = true
      testConnectionString(cadenaConexion)
        .then(data => {
          testConnection.value = false
          isConnectionStringValid.value = data.conexionExitosa
        })
        .catch(() => {
          testConnection.value = false
        })
    }
    const systems = ref([])
    fetchSystems(data => {
      systems.value = data
    })
    /* Events */
    const onSubmit = () => {
      updateConnectionString(connectionstring.value)
        .then(() => {
          router.push({ name: 'settings-dataaccess-connectionstrings' })
        })
    }
    /* Validations */
    const clienteData = ref(JSON.parse(JSON.stringify(connectionstring.value)))
    const resetclienteData = () => {
      clienteData.value = JSON.parse(JSON.stringify(connectionstring))
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetclienteData)
    return {
      // Data
      systems,
      testConnection,
      connectionstring,
      isConnectionStringValid,
      testingConnectionStringIsValid,
      /* Events */
      onSubmit,
      // Validations
      required,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
  data() {
    return {
      passwordFieldTypeNew: 'password',
      passwordFieldTypeConfirm: 'password',
    }
  },
  computed: {
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconConfirm() {
      return this.passwordFieldTypeConfirm === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordConfirm() {
      this.passwordFieldTypeConfirm = this.passwordFieldTypeConfirm === 'password' ? 'text' : 'password'
    },
  },
}
</script>

<style>

</style>
